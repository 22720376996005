//$body-bg: #000;
$theme-colors: (
	"primary": #ac9259,
);

$body-bg: #eaeaea;

$container-max-widths: (
	md: 720px,
	lg: 960px,
	xl: 1400px
) !default;

.component_info {
	display: none;
	background-color: black;
	padding-left: 4px;
	padding-right: 4px;
}
.search_input {
	border-radius: 0.25rem 0rem 0rem 0.25rem !important;
	display: inline !important;
	max-width: 200px !important;
}

.alert-message {
	position: fixed !important;
	top: 56px !important; 
	width: 100%;
	height: 30px;
	margin-bottom: 0px !important;
	padding: 0px !important;
	padding-left: 5px !important;
	padding-top: 2px !important;
}

.search_button {
	border-radius: 0rem 0.25rem 0.25rem 0rem !important;
	display: inline !important;
	max-width: 200px !important;
}

.select-block {
	margin-bottom: 15px;
}

.container {
	margin-top: 30px;
}

.white {
	background-color: white;
	padding-top: 10px;
	padding-bottom: 15px;
}

.grey {
	background-color: grey;
	padding-top: 10px;
	padding-bottom: 15px;
}

.vorschau {
	max-height: 50px;
}

table {
	background-color: white;
	margin-top: 30px;
	border: 1px solid black;
}

.table-picture {
	padding: 0px !important;
	text-align: center;
}

.table td {
	vertical-align: middle !important;
	//padding: 0px !important;
}

th {
	background-color: #343a40;
	color: map-get($theme-colors, "primary");
}

.auswahl {
	background-color: #cdbe9e;
}

.auswahl:hover {
	background-color: #cdbe9e !important;
}

.sold {
	opacity: 0.5;
	background-color: #ddd;
	color: grey;
}

.sold:hover {
	opacity: 1;
	background-color: #ccc !important;
}

.loginContainer {
  height: 100%;
  text-align: center;
}

.loginForm {
	width: 50%;
	margin: 0 auto;
	max-width: 350px;
	padding: 30px;
	border-radius: 20px;
	margin-top: 70px;
	background-color: white;
}

.loginBild {
	width: 170px;
	margin-top: 20px;
	margin-bottom: 30px;
}

.product_list {
	background-color: #444;
	margin-top: 15px;
	margin-bottom: 15px;
	border-radius: 10px;
	padding: 10px;
	cursor: pointer;
	&:hover {
        background-color: #222;
    }
}

.product_list h1 {
	font-size: 1.0em;
	margin-top: 4px;
	margin-bottom: 0;
	color: white;
}

.product_list p {
	color: #ccc;
	margin-bottom: 0;
}

.product_list img {
	max-height: 100px;
}

.source_product {
	border: 2px solid grey;
	border-radius: 0px 0px 15px 15px;
	background-color: #aaa;
	padding: 15px;
}

.source_bild_div {
	background-color: black;
	height: 300px;
	margin-bottom: 15px;
	text-align: center;
}

.tabs-sourceproduct > a {
	background-color: #ccc !important;
	border: 0px solid black !important;
	margin-left: 1px;
}

.tabs-sourceproduct > .active {
	background-color: #aaa !important;
	border: 0px solid black !important;
	margin-left: 1px;
}

.tabs-sourceproduct {
	border-bottom: 0px solid black !important;
}

.source_bild {
	height: 100%;
    width: auto;
    max-width: 100%;
}

.radio_great input[type='radio'] {
	margin-top: 3px;
	width:16px;
	height:16px;
}

.form-control-special {
	//border: 4px solid map-get($theme-colors, "primary") !important;
	//background-color: #e5ddcc !important;
	background-color: #deffde !important;
	color: black;
}

.form-control-danger {
	background-color: #ffdede !important;
	color: black;
}

.no-margin-bottom {
	margin-bottom: 2px !important;	
}


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
// Import Image Gallery Stuff
@import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";
